<template>
    <div>
        <div>
            <h2>
                Campaign Budgets
            </h2>
            <p class="mb-4">
                These are the budgets that will be set when the play is initially deployed.
            </p>
            <div class="campaigns mb-4">
                <v-form v-model="valid">
                    <div class="px-4 pt-4">
                        <h3>Campaigns</h3>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th />
                                <th>
                                    Minimum
                                </th>
                                <th>
                                    Daily Budget
                                </th>
                                <th>
                                    Est. Monthly
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(campaign, index) in campaigns"
                                :key="campaign.remote_name">
                                <td>
                                    {{ campaign.name }}
                                </td>
                                <td>
                                    {{ campaign.minimum_budget | numFormat(numberFormats.currency) }}
                                </td>
                                <td>
                                    <v-text-field
                                        :value="campaign.budget"
                                        :rules="[...requiredRules, ...campaignMinimumBudget(campaign.minimum_budget)]"
                                        prefix="$"
                                        class="styled-field styled-field-white"
                                        @input="updateBudget($event, index)" />
                                </td>
                                <td>
                                    {{ (campaign.budget * 30) | numFormat(numberFormats.currency) }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td />
                                <td>Totals</td>
                                <td>{{ dailyBudgetTotal | numFormat(numberFormats.currency) }}</td>
                                <td>{{ monthlyBudgetTotal | numFormat(numberFormats.currency) }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </v-form>
            </div>
        </div>
        <div class="layout align-center mx-2">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                :disabled="!valid"
                @click="$store.dispatch('goToNextStep')">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import BackButton from '../../../globals/BackButton';
import StyledButton from '../../../globals/StyledButton';
import validationRules from '../../../../helpers/validationRules';
import { mapState, mapGetters } from 'vuex';
import { UPDATE_ONBOARDING_DATA } from '../../../../store/mutation-types';
import { get } from 'lodash';
import getNumberFormats from '../../../../helpers/numberFormats';

export default {
    name: 'OnboardingPlayBudgeting',
    components: {
        BackButton,
        StyledButton,
    },
    data() {
        return {
            valid: false,
            campaigns: [],
            requiredRules: validationRules.requiredRules,
            campaignMinimumBudget: validationRules.campaignMinimumDailyBudget,
            numberFormats: getNumberFormats()
        };
    },
    computed: {
        ...mapState({
            playCampaigns: (state) => state.onboarding.playCampaigns,
            onboardingData: (state) => state.onboarding.data,
        }),
        ...mapGetters([
            'dailyBudgetTotal',
            'monthlyBudgetTotal'
        ])
    },
    watch: {
        campaigns: {
            deep: true,
            handler(campaigns) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, { campaigns });
            }
        }
    },
    created() {

        // If there's existing data and it has the right number of campaigns use it
        const existingCampaigns = get(this.onboardingData, 'campaigns', null) || [];
        if (existingCampaigns.length == this.playCampaigns.length) {
            this.campaigns = existingCampaigns;
        }

        // Otherwise copy over the campaigns and prime the budget with the minimum
        else {
            this.campaigns = this.playCampaigns.map(campaign => {
                campaign.budget = campaign.minimum_budget;
                return campaign;
            });
        }
    },
    methods: {
        updateBudget(value, index) {
            // Ensure a state update occurs
            const newCampaigns = [ ...this.campaigns ];
            newCampaigns[index].budget = (value && isFinite(value)) ? parseFloat(value) : '';
            this.campaigns = newCampaigns;
        },
    }
};
</script>

<style lang="scss" scoped>

.campaigns {
    background-color: rgba($gray, 0.03);
}
table {
    width: 100%;
    border-collapse: collapse;
    th {
        font-weight: 600;
    }
    td, th {
        padding: 10px;
        text-align: center;
        vertical-align: middle;
        &:first-child {
            text-align: left;
            padding-left: 30px;
        }
        &:last-child {
            padding-right: 30px;
        }
        &:nth-child(3) {
            width: 120px;
        }
    }
    tbody {
        &::after {
            content: '';
            display: block;
            height: 20px;
        }
    }
    tfoot {
        td {
            font-weight: 600;
            background-color: rgba($gray, 0.05);
            padding-top: 20px;
            padding-bottom: 20px;
            &:first-child {
                text-align: right;
            }
        }
    }
}
</style>

